import { Common } from '../translations-types';

const translations: Common = {
  save: 'Opslaan',
  submit: 'Bevestigen',
  email: 'E-mailadres',
  close: 'Sluiten',
  salon: 'Vestiging',
  salons: 'Vestigingen',
  address: 'Adres',
  city: 'Plaatsnaam',
  dateOfBirth: 'Geboortedatum',
  fullName: 'Volledige naam',
  gender: 'Geslacht',
  notes: 'Notities',
  phoneNumber: 'Telefoonnummer',
  alternatePhoneNumber: 'Alternatief telefoonnummer',
  zipCode: 'Postcode',
  deleted: 'Verwijderd',
  displayName: 'Schermnaam',
  password: 'Wachtwoord',
  create: 'Aanmaken',
  includeDeleted: 'Inclusief verwijderden',
  confirm: 'Bevestigen',
  update: 'Aanpassen',
  unknown: 'Onbekend',
  description: 'Omschrijving',
  name: 'Naam',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  propertyName: 'Naam eigenschap',
  value: 'Waarde',
  yes: 'Ja',
  no: 'Nee',
  unknownOption: 'Onbekende optie',
  toggle: 'Schakelen',
  send: 'Versturen',
  unknownUser: 'Onbekende gebruiker',
  actions: 'Acties',
  continue: 'Doorgaan',
  edit: 'Aanpassen',
  remove: 'Verwijderen',
  type: 'Type',
  option: 'Optie',
  cancel: 'Annuleren',
  months: {
    january: 'Januari',
    february: 'Februari',
    march: 'Maart',
    april: 'April',
    may: 'Mei',
    june: 'Juni',
    july: 'Juli',
    august: 'Augustus',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  complete: 'Volledig',
  home: 'Start',
  delete: 'Verwijderen',
  areYouSureYouWantToCancel: 'Weet je zeker dat je wilt annuleren?',
  changesWillBeDiscarded: 'Alle wijzigingen worden ongedaan gemaakt.',
  language: 'Taal',
  search: 'Zoeken',
  title: 'Titel',
  changeImage: 'Wijzigen foto',
  profileImage: 'Profiel foto',
  skip: 'Overslaan',
  reload: 'Opnieuw laden',
  goToHomepage: 'Ga naar Homepage',
  add: 'Toevoegen',
  questionnaire: 'Vragenlijst',
  question: 'Vraag',
  notSet: 'Niet gezet',
  icon: 'Icoon',
  month: 'Maand',
  year: 'Jaar',
  days: 'Dagen',
  download: 'Download',
  whatsapp: 'Whatsapp',
  url: 'Url',
  text: 'Tekst',
  loading: 'Laden',
  agree: 'Akkoord',
  disagree: 'Niet akkoord',
  translate: 'Vertalen',
  translations: 'Vertaling',
  next: 'Volgende',
  hidden: 'Verborgen',
  or: 'Of',
  optionIsNotPurchased: 'Upgrade uw abonnement om toegang te krijgen tot deze functie.',
};

export default translations;
