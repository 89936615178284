import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import enLocale from 'antd/es/locale/en_GB';
import nlLocale from 'antd/es/locale/nl_NL';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { setupTranslations } from 'common/i18n';
import WebFontLoader from 'webfontloader';
import { getTheme } from 'common/styles';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { RESPONSIVE_WIDTH, RUNNING_IN_PRODUCTION } from 'common/app-constants';
import setupTheme from 'common/styles/setupTheme';
import LocalStorageService from 'common/services/local-storage-service';
import { SupportedLanguage } from 'common/api/generated-api';
import { ApplicationCrashed } from 'common/components/error-pages';
import { RoutePath } from 'common/routes/types';
import AntdContextWrapper from 'common/components/AntdContextWrapper';
import * as Sentry from '@sentry/react';
import 'common/3rd-parties/sentry';

const AdminMain = React.lazy(() => import('./admin'));
const CustomerMain = React.lazy(() => import('./customer'));
const GlobalAdminMain = React.lazy(() => import('./global-admin'));
const TermsAndConditions = React.lazy(() => import('customer/views/TermsAndConditions'));
const ShortcutCodeResolver = React.lazy(() => import('customer/views/ShortcutCodeResolver'));
const Overview = React.lazy(() => import('./Overview'));
const CreateOrganisation = React.lazy(() => import('admin/views/create-organisation'));

const antLocaleMap = {
  [SupportedLanguage.Nl]: nlLocale,
  [SupportedLanguage.En]: enLocale,
};

const GlobalStyle = createGlobalStyle`
  #root, html {
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
    height: 100%;
  }

  * {
    font-family: 'Inter' !important;
  }

  a {
    text-decoration: none;
  }

  .ant-modal-mask {
    z-index: 1200;
  }

  .ant-modal-wrap {
    z-index: 1300;
  }

  .ant-select-dropdown {
    z-index: 1350;
  }

  .ant-tooltip {
    z-index: 1350;
  }
`;

const isAppResponsive = () => window.innerWidth <= RESPONSIVE_WIDTH;

setupTranslations();
const Main = () => {
  const [responsive, setResponsive] = useState(isAppResponsive());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const theme = useMemo(
    () =>
      getTheme({
        responsive,
        condensedMode: LocalStorageService.getCondensedMode(),
        windowWidth,
        windowHeight,
      }),
    [responsive, windowWidth, windowHeight],
  );

  useEffect(() => {
    WebFontLoader.load({ google: { families: ['Inter:300,400,500,700', 'Belleza:400'] } });
    setupTheme(theme);
  }, [theme]);

  useEffect(() => {
    if (!('ResizeObserver' in window)) {
      setResponsive(isAppResponsive());
      setWindowWidth((window as Window).innerWidth);
      setWindowHeight((window as Window).innerHeight);

      return () => {};
    }

    const observer = new window.ResizeObserver(() => {
      setResponsive(isAppResponsive());
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    });
    observer.observe(window.document.documentElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ConfigProvider
        locale={antLocaleMap[LocalStorageService.getUserLanguage()]}
        theme={{
          token: {
            colorPrimary: theme.colors.primary,
            colorLink: theme.colors.primary,
          },
        }}
      >
        <AntdContextWrapper>
          <Sentry.ErrorBoundary fallback={<ApplicationCrashed />}>
            <React.Suspense fallback={<span />}>
              <BrowserRouter>
                <Routes>
                  <Route path={RoutePath.newQuestionnaire} element={<ShortcutCodeResolver />} />
                  <Route path={RoutePath.shortcut} element={<ShortcutCodeResolver />} />
                  <Route path={RoutePath.termsAndConditions} element={<TermsAndConditions />} />
                  <Route path={RoutePath.createOrganisation} element={<CreateOrganisation />} />
                  <Route path='/global-admin/*' element={<GlobalAdminMain />} />
                  <Route path='/admin/*' element={<AdminMain />} />
                  <Route path='/:slug/admin/*' element={<AdminMain />} />
                  <Route path='/:slug/*' element={<CustomerMain />} />
                  {!RUNNING_IN_PRODUCTION && <Route path='/' element={<Overview />} />}
                </Routes>
              </BrowserRouter>
            </React.Suspense>
          </Sentry.ErrorBoundary>
        </AntdContextWrapper>
      </ConfigProvider>
    </ThemeProvider>
  );
};

const root = createRoot(document.getElementById('root')!);
root.render(<Main />);
