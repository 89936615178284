import { CreateOrganisation } from '../translations-types';

const translations: CreateOrganisation = {
  createOrganization: 'Aanmaken organisatie',
  successfullyCreated: 'Organisatie succesvol aangemaakt',
  successfullyUpdated: 'Aanpassen organisatie succesvol',
  createOrganizationSubtitle: 'Organisatie omschrijving',
  legalCompanyName: 'Officiële bedrijfsnaam',
  companyNameToUse: 'Weergave bedrijfsnaam',
  organizationWebAddress: 'Url organisatienaam',
  slugTooltip: 'Wordt gebruikt om een unieke url te generen voor je organisatie.',
  youAgree: 'Door te registreren ga je akkoord met onze',
  termsAndConditions: 'Algemene voorwaarden en verwerkingsovereenkomst',
  vatNumber: 'BTW nummer',
  invoiceLanguage: 'Factuur taal',
  country: 'Land',
  address: 'Adresregel 1',
  address2: 'Adresregel 2',
  postalCode: 'Postcode',
  city: 'Plaats',
  showDifferentName: 'Toon aan cliënten een alternatieve bedrijfsnaam',
  companyNameToUseTooltip: 'Dit is de bedrijfsnaam die cliënten zien',
};

export default translations;
